const projectTypeTranslation: { [key: string]: string } = {
  "oeffentliche-baeder": "Öffentliche Naturbäder",
  "private-schwimmteiche": "Private Schwimmteiche",
  "wasserspielplaetze-spruehfelder": "Wasserspielplätze/Sprühfelder",
  "eingriffs-ausgleichsplanung": "Eingriffs- und Ausgleichsplanung",
  campingplaetze: "Campingplätze",
  "gruenanlagen-gaerten": "Grünanlagen, Gärten",
};

export default projectTypeTranslation;
